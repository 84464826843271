import React from "react"
import styled from "styled-components"

import Footer from "../components/Footer"
import TopBar from "../components/TopBar"
import Layout from "../containers/Layout"

import { Colors, Spacing, Typography } from "../rules"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Historia = () => {
  const data = useStaticQuery(graphql`
    query {
      history_1: file(relativePath: { eq: "historia/Luis_amigo.jpeg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      history_2: file(relativePath: { eq: "historia/2011.jpeg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      history_3: file(relativePath: { eq: "historia/2012.jpeg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      history_4: file(relativePath: { eq: "historia/2014.jpeg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      history_5: file(relativePath: { eq: "historia/2016-2017.jpeg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      history_6: file(relativePath: { eq: "historia/2018-2019.jpeg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      history_7: file(relativePath: { eq: "historia/2020.jpeg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      history_8: file(relativePath: { eq: "historia/Jovenes-2020.jpeg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <TopBar />
      <Main>
        <Title>Historia</Title>
        <Section>
          <p>
            Fénix Rugby Club nace en el año 2006, como proyecto universitario de
            un grupo de jóvenes estudiantes de la FUNLAM, con múltiples
            dificultades en sus inicios, pero siempre con la firme convicción de
            ser un equipo competitivo y reconocido a nivel nacional.
          </p>
          <HistoryPhoto>
            <Img
              fluid={data.history_1.childImageSharp.fluid}
              alt={"history_1_photo"}
            />
          </HistoryPhoto>
          <p>
            Fénix Rugby Club inició su carrera deportiva en la LAR (Liga
            Antioqueña de Rugby) en tercera división, saliendo campeones y
            escalando de categoría durante 4 años hasta llegar a la categoría
            primera “ La Elite del Rugby Nacional”, en la cual nos mantenemos
            desde el 2011 y por el excelente desempeño deportivo nos hemos
            ganado la participación en diferentes torneos Regionales y
            Nacionales.
          </p>
          <HistoryPhoto>
            <Img
              fluid={data.history_2.childImageSharp.fluid}
              alt={"history_2_photo"}
            />
          </HistoryPhoto>
          <HistoryPhoto>
            <Img
              fluid={data.history_3.childImageSharp.fluid}
              alt={"history_3_photo"}
            />
          </HistoryPhoto>
          <p>
            Fénix Rugby Club se ha caracterizado por ser un club aguerrido lleno
            de jugadores con experiencia, valores, y sobre todo amor por el
            club.
          </p>
          <HistoryPhoto>
            <Img
              fluid={data.history_4.childImageSharp.fluid}
              alt={"history_4_photo"}
            />
          </HistoryPhoto>
          <HistoryPhoto>
            <Img
              fluid={data.history_5.childImageSharp.fluid}
              alt={"history_5_photo"}
            />
          </HistoryPhoto>
          <HistoryPhoto>
            <Img
              fluid={data.history_6.childImageSharp.fluid}
              alt={"history_6_photo"}
            />
          </HistoryPhoto>
          <HistoryPhoto>
            <Img
              fluid={data.history_7.childImageSharp.fluid}
              alt={"history_7_photo"}
            />
          </HistoryPhoto>
          <p>
            Se cuenta en el momento con un proceso de juveniles con edades entre
            los 14 a los 17 que muestran el mismo amor por los colores{" "}
            <span style={{ color: Colors.primary.blue.darker }}>AZUL</span> y
            <span style={{ color: Colors.primary.fire.darker }}> NARANJA</span>{" "}
            característicos del club.
          </p>
          <p>
            Militan en nuestro club importantes figuras del Rugby Nacional, con
            participación activa en Torneos Internacionales en diferentes
            modalidades (7s – 15́s) y categorías (M16 – M18 – M20 – Mayores) en
            selecciones Antioquia y Colombia “Tucanes”.
          </p>
          <p>Logros obtenidos en el último años:</p>
          <ul>
            <li>Campeones torneos LAR segundo semestre</li>
            <li>Participación del torneo Copa Colombia</li>
            <li>
              Participación del torneo súper 20 (los mejores 20 equipos de
              Colombia).
            </li>
          </ul>
          <HistoryPhoto>
            <Img
              fluid={data.history_8.childImageSharp.fluid}
              alt={"history_8_photo"}
            />
          </HistoryPhoto>
          Contamos con tres categorías:
          <ul>
            <li>FÉNIX RC: Masculino mayores </li>
            <li>FÉNIX RC: Masculino juveniles </li>
            <li>FÉNIX RC: Masculino semilleros</li>
          </ul>
        </Section>
      </Main>
      <Footer />
    </Layout>
  )
}

const Main = styled("div")`
  flex: 1 0 auto;
  justify-content: center;
  color: ${Colors.utility.paragraph.default};
  margin: ${Spacing.lg};
`

const Section = styled("div")`
  display: flex;
  flex-direction: column;
  border: ${props => props.border && props.border};
  border-width: ${props => props.borderWidth && props.borderWidth};
  margin: 0 auto;
  max-width: 42rem;
`

const Title = styled("div")`
  text-align: center;
  margin: ${Spacing.lg} 0;
  font-size: ${Typography.title.fontSize};
  font-weight: 600;
`

const HistoryPhoto = styled("div")`
  margin: ${Spacing.md} auto;
  width: 100%;
`

export default Historia
